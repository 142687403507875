import NavbarMobile from "./Nav/NavbarMobile";
import HomeMobile from "./pages/Home/HomeMobile";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FooterMobile from "./Nav/FooterMobile";

const DesktopApp = () => {
  return (
    <Router>
      <div className="App">
        <NavbarMobile />
        <Switch>
          <Route exact path="/">
            <HomeMobile />
          </Route>
          <Route path="/services">Services</Route>
          <Route path="/projects">Projects</Route>
          <Route path="/support">Support</Route>
        </Switch>
        <FooterMobile />
      </div>
    </Router>
  );
};

export default DesktopApp;
