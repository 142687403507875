import './Service.css'
import BasisCard from '../Card/BasisCard'

const Service = () => {
  return (
    <div className="double_contain">
      <div className="double_content">
        <div className="service">
          <div className="service_contain">
            <div className="service_title">
              <h2>What Services Do We Provide?</h2>
            </div>
            <div className="service_content">
              <p>
                Our team of expert developers, designers, and strategists work
                together with high synergy to turn your vision into powerful and
                user-friendly software solutions. By combining technical expertise,
                creative design, and careful strategy, we ensure that every project
                we undertake delivers maximum value and an outstanding user
                experience. We are committed to bringing your ideas from concept to
                implementation with unmatched quality and excellence.
              </p>
            </div>
          </div>
          <div className="service_image_container">
            <img src={`${process.env.PUBLIC_URL}/res/service/Service_Image.svg`} alt="" className='service_image'></img>
          </div>
        </div>
        <div className="cards">
          <BasisCard />
        </div>
      </div>
    </div>
  );
};

export default Service;
