import './ServiceMobile.css'
import BasisCard from '../Card/BasisCardMobile'

const ServiceMobile = () => {
  return (
    <div className="double_content_mobile">
      <div className="service_mobile">
        <div className="service_image_container_mobile">
          <img
            src={`${process.env.PUBLIC_URL}/res/service/Service_Image.svg`}
            alt=""
            className="service_image_mobile"
          ></img>
        </div>
        <div className="service_contain_mobile">
          <div className="service_title_mobile">
            <h2>What Services Do We Provide?</h2>
          </div>
          <div className="service_content_mobile">
            <p>
              Our team of expert developers, designers, and strategists work
              together with high synergy to turn your vision into powerful and
              user-friendly software solutions. By combining technical
              expertise, creative design, and careful strategy, we ensure that
              every project we undertake delivers maximum value and an
              outstanding user experience. We are committed to bringing your
              ideas from concept to implementation with unmatched quality and
              excellence.
            </p>
          </div>
        </div>
      </div>
      <div className="cards_mobile">
        <BasisCard />
      </div> 
    </div>
  );
};

export default ServiceMobile;
