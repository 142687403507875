// import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {

  return (
    <nav className="navbar">
      <div className="logoMark">
        <img
          src={`${process.env.PUBLIC_URL}/res/Logo.svg`}
          alt=""
          className="logo"
        />
      </div>
      {/* <div className={`links`}>
        <Link to="/">Home</Link>
        <Link to="/services">Services</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/support">Support</Link>
      </div>
      <div className="extra"></div> */}
    </nav>
  );
};

export default Navbar;
