import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import './BasisCard.css';
//import useCardClick from "../Hooks/cardClick";
//import Explain from "../Explain/Explain";

const data = [
  {
    id: 1,
    title: "Blockchain",
    desc: "We are delivering innovative blockchain solutions tailored to your needs. From developing decentralized applications to integrating smart contracts, our expert team is here to help you leverage blockchain technology for greater efficiency and transparency.",
    icon: `${process.env.PUBLIC_URL}/icon/Blockchain-Logo.svg`,
    address: "",
  },
  {
    id: 2,
    title: "Crypto Currency Wallet",
    desc: "Whether you're developing a new wallet or seeking enhancements for an existing one, our team of experts is ready to help. Leverage our experience and cutting-edge solutions to bring your crypto wallet project to life with the highest level of security and innovation.",
    icon: `${process.env.PUBLIC_URL}/icon/Crypto-Logo.svg`,
    address: "",
  },
  {
    id: 3,
    title: "Payment Service",
    desc: " We specialize in designing and implementing cutting-edge payment solutions tailored to your business needs. From secure transactions to seamless integrations, our team ensures your payment services are efficient and up-to-date.",
    icon: `${process.env.PUBLIC_URL}/icon/Payment-Logo.svg`,
    address: "",
  },
  {
    id: 4,
    title: "Mobile App",
    desc: "We accept mobile app projects and are ready to bring your vision to life with cutting-edge design and development. Whether you need a sleek, user-friendly app or a complex, feature-rich solution, our expert team is here to deliver exceptional results.",
    icon: `${process.env.PUBLIC_URL}/icon/Mobile-Logo.svg`,
    address: "",
  },
  {
    id: 5,
    title: "Website Development",
    desc: "We accept website development projects, specializing in creating responsive, high-performance websites that look great on any device. Our team will deliver a seamless, user-friendly experience tailored to your business needs.",
    icon: `${process.env.PUBLIC_URL}/icon/Mobile-Logo.svg`,
    address: "",
  },
];

const BasisCard = () => {
  
  return (
    <div className="cardContain">
      <div className="cardContainer">
        {data.slice(0, 3).map((content) => (
          <Card
            spacing={2}
            sx={{
              maxWidth: 354,
              maxHeight: 450,
              padding: 2,
              margin: 2,
              backgroundColor: "#303030",
              borderRadius: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className="imageContain">
              <CardMedia
                component="img"
                alt={content.title}
                height="4"
                width="4"
                image={content.icon}
                className="card_image"
                margin="20px"
              />
            </div>
            <CardContent className="content">
              <Typography variant="h5" component="h2" className="cardTitle">
                {content.title}
              </Typography>
              <Typography variant="" color="textSecondary" className="cardDesc">
                {content.desc}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="bottom-row cardContainer">
        {data.slice(3).map((content) => (
          <Card
            spacing={2}
            sx={{
              maxWidth: 354,
              maxHeight: 450,
              padding: 2,
              margin: 2,
              backgroundColor: "#303030",
              borderRadius: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className="imageContain">
              <CardMedia
                component="img"
                alt={content.title}
                height="4"
                width="4"
                image={content.icon}
                className="card_image"
                margin="20px"
              />
            </div>
            <CardContent className="content">
              <Typography variant="h5" component="h2" className="cardTitle">
                {content.title}
              </Typography>
              <Typography variant="" color="textSecondary" className="cardDesc">
                {content.desc}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default BasisCard;
