import "./Explain.css";
// import Button from '@mui/material/Button'

const Explain = () => {
  return (
    <div className="containerEx">
      <div className="explainContainer">
        <div className="explain_images">
          <img
            src={`${process.env.PUBLIC_URL}/explain/Blockchain-Explain.svg`}
            alt=""
            className="explimage"
          />
        </div>
        <div className="explainCont">
          <div className="explain_title">
            <h3 className="title_explain">BlockChain</h3>
          </div>
          <p className="explain_text">
            Blockchain is a digital ledger technology that records transactions
            in a decentralized and secure manner. It involves a chain of blocks,
            each containing data, that are linked and verified by a network of
            computers, ensuring transparency and immutability.
          </p>
          {/* <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#FF8900",
              borderRadius: "8px",
              maxWidth: "160px",
              maxHeight: "50px",
              minWidth: "160px",
              minHeight: "50px",
            }}
          >
            Start Now
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Explain;
