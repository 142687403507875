import Navbar from "./Nav/Navbar";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Nav/Footer";

const DesktopApp = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/services">Services</Route>
          <Route path="/projects">Projects</Route>
          <Route path="/support">Support</Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default DesktopApp;
