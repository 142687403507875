// import NavLinks from "./NavLinks";
import "./NavbarMobile.css";
// import { useState } from "react";
// import { CiMenuBurger } from "react-icons/ci";
// import { MdClose } from "react-icons/md";

const Navbar = () => {

  // const Hamburger = 
  //   <CiMenuBurger
  //     className="HamburgerMenu"
  //     size="30px"
  //     color="white"
  //     onClick={() => setClick(!click)}
  //   />

  // const [click, setClick] = useState(false);

  // const Close = 
  //   <MdClose className="HamburgerMenu"
  //     size="30px"
  //     color="white"
  //     onClick={() => setClick(!click)}
  //   />

  //   const closeMenu = () => setClick(false)

  return (
    <nav className="navbar_mobile">
      <div className="logoMark_mobile">
        <img
          src={`${process.env.PUBLIC_URL}/res/Logo.svg`}
          alt=""
          className="logo"
        />
      </div>
      {/* 
        { click ? Close : Hamburger}
        {click && <NavLinks isClicked={true} closeMenu={closeMenu}/>}
      
      */}
    </nav>
  );
};

export default Navbar;
