//import { Link } from "react-router-dom";
//import Button from '@mui/material/Button';
import './Home.css'
import Top from './component/Top/Top';
import Service from './component/Service/Service';
import Explain from "./component/Explain/Explain";
import Project from "./component/Project/Project";
import ProjectRevised from './component/Project/ProjectRevised';
import { useRef } from 'react';

const Home = () => {
  const serviceRef = useRef(null);

  return (
    <div className="homes">
      <div className="home_content">
        <Top serviceRef={serviceRef}/>
        <div ref={serviceRef}></div>
        <Service />
        <Explain />
        <ProjectRevised/>
      </div>
      {/* 
      <div className="topcontain">
        <div className="top">
          <div className="toptitle">
            <h1>Transforming Ideas into Digital Reality</h1>
          </div>
          <div className="topcon">
            <p>
              Welcome to Gundadhita Informatika Software House, where innovation
              meets execution. Our team of expert developers, designers, and
              strategists work together to turn your vision into powerful,
              user-friendly software solutions. Whether you need a sleek mobile
              app, a robust web platform, or custom software tailored to your
              unique needs, we've got you covered.
            </p>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#FF8900",
              }}
            >
              Start Now
            </Button>
          </div>
        </div>
      </div>
      <div className="servicecontain">
        <div className="service">
          <div className="servicetitle">
            <h1>What Services Do We Provide?</h1>
          </div>
          <div className="sercont">
            <p>
              Our team of expert developers, designers, and strategists work
              together with high synergy to turn your vision into powerful and
              user-friendly software solutions. By combining technical
              expertise, creative design, and careful strategy, we ensure that
              every project we undertake delivers maximum value and an
              outstanding user experience. We are committed to bringing your
              ideas from concept to implementation with unmatched quality and
              excellence.
            </p>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#FF8900",
              }}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <Card />
      </div>
      <div className="explain">
        <Explain />
      </div>
      <div className="projectContainer">
        <div className="protitle">
          <h2>Our Projects</h2>
        </div>
        <div className="project-list">
          <Project />
        </div>
      </div>
      <div className="foot">
        <div className="footer">
          <div className="foot-left">
            <div className="lefttitle">
              <h3>Ready to get started?</h3>
            </div>
            <div className="footcont">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#303030",
              }}
            >
              Get Started
            </Button>
          </div>
          <div className="link">
            <ul>
              <li>
                <Link to="/">Navigation</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/">Email Marketing</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/">Campaigns</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/">Branding</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/">Office</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default Home;
