import Button from "@mui/material/Button";
import './Top.css'
import { forwardRef } from "react";

const Top = forwardRef((props, ref) => {
  const handleScrollButtonClick = () => {
    props.serviceRef.current.scrollIntoView({behavior: 'smooth'})
  };

  return (
    <div className="topcontain">
      <div className="top">
        <div className="toptitle">
          <h1>Transforming Ideas into Digital Reality</h1>
        </div>
        <div className="topcon">
          <p>
            Welcome to Gundadhita Informatika Software House, where innovation
            meets execution. Our team of expert developers, designers, and
            strategists work together to turn your vision into powerful,
            user-friendly software solutions. Whether you need a sleek mobile
            app, a robust web platform, or custom software tailored to your
            unique needs, we've got you covered.
          </p>
        </div>
        <div className="button_top">
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#FF8900",
              borderRadius: "8px",
              maxWidth: "160px",
              maxHeight: "50px",
              minWidth: "160px",
              minHeight: "50px",
            }}
            onClick={handleScrollButtonClick}
          >
            Start Now
          </Button>
        </div>
      </div>
      <div className="top_image">
        <img src={`${process.env.PUBLIC_URL}/res/top/Top_Image.svg`} alt="" className="top_images"></img>
      </div>
    </div>
  );
});

export default Top;
