import './App.css'
import DesktopApp from '../Desktop/DesktopApp';
import MobileApp from '../Mobile/MobileApp';
import { useMediaQuery } from 'react-responsive';

function App() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div>
      {isMobile ? <MobileApp /> : <DesktopApp />}
    </div>
  )
}

export default App;
