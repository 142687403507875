import './ExplainMobile.css';
// import Button from '@mui/material/Button'

const ExplainMobile = () => {
    return (
      <div className="containerEx_mobile">
        <div className="explainContainer_mobile">
          <div className="explain_images_mobile">
            <img
              src={`${process.env.PUBLIC_URL}/explain/Blockchain-Explain.svg`}
              alt=""
              className="image_mobile"
            />
          </div>
          <div className="explainCont_mobile">
            <div className="explain_desc_mobile">
              <div className="explain_title_mobile">
                <h3>BlockChain</h3>
              </div>
              <p className="explain_text_mobile">
                Blockchain is a digital ledger technology that records
                transactions in a decentralized and secure manner. It involves a
                chain of blocks, each containing data, that are linked and
                verified by a network of computers, ensuring transparency and
                immutability.
              </p>
            </div>
            <div className="explain_button_mobile">
              {/* <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#FF8900",
                  borderRadius: "8px",
                  maxWidth: "160px",
                  maxHeight: "50px",
                  minWidth: "160px",
                  minHeight: "50px",
                }}
              >
                Start Now
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default ExplainMobile;