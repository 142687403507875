import './ProjectMobile.css';

const data = [
  {
    id: "1",
    title: "Dhita.net",
    project:
      "Discover Dhita.net, the ultimate app for project management and employee attendance, all verified by GPS location. With Dhita.net, streamline your workflow and track employee attendance with pinpoint accuracy, ensuring that every project stays on track and every team member is where they need to be. Simplify your operations and enhance accountability with our intuitive platform, designed to make managing your projects and workforce easier than ever. Experience seamless integration and precise control with Dhita.net—where efficiency meets reliability.",
    image: `${process.env.PUBLIC_URL}/res/images/Dhita.svg`,
  },
  {
    id: "2",
    title: "Romilius",
    project:
      "Introducing Romilius, the ultimate payment aggregator designed to revolutionize your transactions. Seamlessly integrating foreign exchange, cryptocurrency, and traditional banking, Romilius connects merchants with banks to offer a unified, efficient payment solution. Whether you're looking to streamline cross-border payments or explore innovative crypto options, Romilius simplifies and secures your financial interactions in one powerful app. Experience the future of payments with Romilius—where innovation meets convenience.",
    image: `${process.env.PUBLIC_URL}/res/images/Romilius.svg`,
  },
  {
    id: "3",
    title: "dst.technology Remintance",
    project:
      "Introducing dst.technology, your go-to app for seamless financial solutions. Whether you need to transfer money quickly or make secure payments, dst.technology offers a user-friendly experience with enhanced features. Streamline your financial transactions with ease, and enjoy fast, reliable service right at your fingertips. Embrace a smarter way to manage your money with dst.technology — where convenience and innovation come together.",
    image: `${process.env.PUBLIC_URL}/res/images/dsttech.svg`,
  },
];

const ProjectMobile = () => {
  return (
    <div className="projectContainer_mobile">
      <div className="project_heading_mobile">
        <h4>Our Projects</h4>
      </div>
      <div className="projectList_mobile">
        {data.map((project) => {
          return (
            <div className="list_mobile">
              <div className="proContent_mobile">
                <div className="projectContent_mobile">
                  <div className="project_title_mobile">{project.title}</div>
                  <div className="project_desc_mobile">{project.project}</div>
                </div>
              </div>
              <div className="projectImage_mobile">
                <img src={project.image} alt={project.title} className={`projectImageMobile ${project.id === "3" ? "scaled" : "unscaled"}`}/>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectMobile;
