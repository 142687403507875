//import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import './Footer.css'

const Footer = () => {
  return (
    <div className="foot">
      <div className="footer">
        <div className="lefttitle">
          <h3>Ready to get started?</h3>
        </div>
        <div className="footcont">
          <p>
            Realize your vision here with our help of expert developers,
            designers, and strategist now.
          </p>
        </div>
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "#303030",
            maxWidth: "160px",
            maxHeight: "50px",
            minWidth: "160px",
            minHeight: "50px",
          }}
          href={`mailto:info@gdti.co.id`}
        >
          Contact Us
        </Button>
        <p>or email us at info@gdti.co.id</p>
      </div>
      {/* 
      <div className="link">
        <ul>
          <li>
            <Link to="/">Navigation</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">Email Marketing</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">Campaigns</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">Branding</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">Office</Link>
          </li>
        </ul>
      </div>
      */}
    </div>
  );
};

export default Footer;
