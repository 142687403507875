import "./ProjectRevised.css"

const ProjectRevised = () => {
    return (
      <>
        <div className="project_container">
          <div className="project_title">
            <h3>Our Projects</h3>
          </div>
          <div className="project_list">
            <div className="project_list_1">
              <div className="project_explain">
                <div className="project_name">Dhita.net</div>
                <div className="project_desc">
                  Discover Dhita.net, the ultimate app for project management
                  and employee attendance, all verified by GPS location. With
                  Dhita.net, streamline your workflow and track employee
                  attendance with pinpoint accuracy, ensuring that every project
                  stays on track and every team member is where they need to be.
                  Simplify your operations and enhance accountability with our
                  intuitive platform, designed to make managing your projects
                  and workforce easier than ever. Experience seamless
                  integration and precise control with Dhita.net—where
                  efficiency meets reliability.
                </div>
              </div>
              <div className="project_image">
                <img
                  src={`${process.env.PUBLIC_URL}/res/images/Dhita.svg`}
                  alt=""
                  className="project_image_source"
                />
              </div>
            </div>
            <div className="project_list_2">
              <div className="project_image">
                <img
                  src={`${process.env.PUBLIC_URL}/res/images/Romilius.svg`}
                  alt=""
                  className="project_image_source"
                />
              </div>
              <div className="project_explain">
                <div className="project_name">Romilius</div>
                <div className="project_desc">
                  Introducing Romilius, the ultimate payment aggregator designed
                  to revolutionize your transactions. Seamlessly integrating
                  foreign exchange, cryptocurrency, and traditional banking,
                  Romilius connects merchants with banks to offer a unified,
                  efficient payment solution. Whether you're looking to
                  streamline cross-border payments or explore innovative crypto
                  options, Romilius simplifies and secures your financial
                  interactions in one powerful app. Experience the future of
                  payments with Romilius—where innovation meets convenience.
                </div>
              </div>
            </div>
            <div className="project_list_3">
              <div className="project_explain">
                <div className="project_name">dst.technology Remintance</div>
                <div className="project_desc">
                  Introducing dst.technology, your go-to app for seamless
                  financial solutions. Whether you need to transfer money
                  quickly or make secure payments, dst.technology offers a
                  user-friendly experience with enhanced features. Streamline
                  your financial transactions with ease, and enjoy fast,
                  reliable service right at your fingertips. Embrace a smarter
                  way to manage your money with dst.technology — where
                  convenience and innovation come together.
                </div>
              </div>
              <div className="project_image_dst">
                <img
                  src={`${process.env.PUBLIC_URL}/res/images/dsttech.svg`}
                  alt=""
                  className="project_image_source"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ProjectRevised